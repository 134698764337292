<template>
    <div class="content">
      <div class="search">
        <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="车辆入场记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="车辆入场记录">
            <el-button type="primary" class="el-icon-download"> 导出</el-button>
        </JsonExcel>
        <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
        <el-dropdown style="margin-left: 10px;" @command="clearCommand">
          <el-button type="primary">
            清理在场记录<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="a" class="a">强制出场</el-dropdown-item>
            <el-dropdown-item command="b" class="a">清空所有在场车辆</el-dropdown-item>
            <el-dropdown-item command="c" class="a">按条件清除</el-dropdown-item>
            <el-dropdown-item command="d" class="a">补录入场</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>车牌号：</label>
                <el-input v-model="searchForm.plateno" class="input-with-select" style="width:200px">
                  <!-- <el-tooltip slot="append" class="item" effect="dark" content="查询一位多车,其他车辆在场情况" placement="right-end">
                    <el-button  icon="el-icon-search" @click="find"></el-button>
                  </el-tooltip> -->
                </el-input>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label style="width: 150px;">入场时间：</label>
                 <el-date-picker v-model="searchForm.intime" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>卡类：</label>
                <el-select v-model="searchForm.card_id" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in cardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>所在车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                    <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                    <el-option v-for="item in parkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

         <el-table ref="enterTable" :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini" @expand-change="expandChanged">
          <el-table-column type="selection" align="center" width="45"></el-table-column>
          <el-table-column type="expand">
            <template slot-scope="slot">
               <div>
                  <span style="margin-left:30px;font-size:15px">入场图片：</span>
                  <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px" height='700px'  :src='slot.row.picdata[0]' fit="fill" @click="ImgClick"></el-image>
                  <el-dialog title="入场图片：" :visible.sync="imgDialogVisible" :append-to-body='true'>
                      <img width="100%" :src="slot.row.picdata[0]" alt="">
                  </el-dialog>
                </div>
            </template>
          </el-table-column>
          <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
          <el-table-column align="center" prop="log_plateno" label="车牌号"></el-table-column>
          <el-table-column align="center" prop="car_name" label="姓名"></el-table-column>
          <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
          <el-table-column align="center" prop="log_car_type" label="车型">
            <template slot-scope="scope">
              {{scope.row.log_car_type===0?'小车':'大车'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="log_in_time" label="入场时间">
            <template slot-scope="scope">
              {{FormData(scope.row.log_in_time)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="gt_name" label="入场通道"></el-table-column>
          <el-table-column align="center" prop="post_name" label="入场岗亭"></el-table-column>
          <el-table-column align="center" prop="pk_name" label="所在车场"></el-table-column>
          <el-table-column align="center" prop="user_name" label="操作员"></el-table-column>
          <el-table-column align="center" prop="log_remark" label="入场备注"></el-table-column>
          <el-table-column align="center" prop="log_card_status" label="卡类改变原因">
             <template slot-scope="scope">
              {{ChangeCardTypeReason(scope.row.log_card_status)}}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>


        <!---按条件清除-->
        <el-dialog :title="title" :visible.sync="dialogVisible" :width='clientWidth<900?"50%":(clientWidth<1200?"40%":"30%")' :append-to-body='true'>
          <el-form :model="clearForm" label-width="100px" size='medium'>
              <el-form-item label="车牌号">
                  <el-input v-model="clearForm.plateno"></el-input>
              </el-form-item>
              <el-form-item label="车辆类型">
                  <el-select v-model="clearForm.card_id" style="width:100%;">
                    <el-option label=" " :value='0'></el-option>
                    <el-option label="临时车" :value="1"></el-option>
                    <el-option v-for="item in CardTypeDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="入场时间" >
                <el-date-picker v-model="clearForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submit">确 定</el-button>
          </span>
        </el-dialog>

        <!---补录-->
        <el-dialog :title="title" :visible.sync="BLdialogVisible" width='25%' :append-to-body='true'>
          <el-form :model="blform" ref="blform" :rules="rules" label-width="100px" size='medium'>
              <el-form-item label="车牌号" prop="plateno">
                  <el-input v-model="blform.plateno"></el-input>
              </el-form-item>
              <el-form-item label="入场时间" prop="begintime">
                <el-date-picker v-model="blform.begintime" type="datetime" style="width: 100%;" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="BL">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      clientWidth:0,
      User:null,
      searchForm: {
        plateno: '',
        pk_id: 0,
        card_id: 0,
        intime: [],
        pageSize: 15,
        pageNo: 1,
        total: 0
      },
      CardTypeDataList: [],
      ParkDataList: [],

      DataList: [],
      imgDialogVisible: false,

       // 导出
      exportDataStandard: {
        所属车场: "park_name" ,
        车牌号: "log_plateno" ,
        姓名: "car_name" ,
        卡型:'card_name',
        车型: {
          field: 'log_car_type',
          callback: value => {
            return value===0?'小车':'大车'
          }
        },
        所在车场: "pk_name" ,
        入场时间: {
          field: 'log_in_time',
          callback: value => {
            return this.FormData(value)
          }
        },
        入场通道: "gt_name" ,
        入场岗亭: "post_name" ,
        操作员: "user_name" ,
        卡类改变原因:{
          field: 'log_card_status',
          callback: value => {
            return this.ChangeCardTypeReason(value)
          }
        }
      },
      exportData: [],

      title:'',
      dialogVisible:false,
      clearForm:{
        plateno:'',
        card_id:0,
        time:[]
      },

      BLdialogVisible:false,
      rules:{
        plateno: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        begintime: [{ required: true, message: '请输入入场时间', trigger: 'blur' }],
      },
      blform:{
        plateno:'',
        begintime:''
      }
    }
  },
  computed: {
    FormData () {
      return function (time) {
        if(time===null||time===undefined||time==='')
          return ''
        else
          return util.FormateDate(time, 2)
      }
    },
    ChangeCardTypeReason () {
      return function (t) {
        if (t === -1) {
          return '一位多车，按临时车进'
        } else if (t === -2) {
          return '已过期，按临时车进'
        } else { return '' }
      }
    },
    parkDataList(){
      if(this.User.user_type===5){
          return this.$store.state.ParkDataList
        }
      else{
        return this.ParkDataList
      }
    },
    cardDataList(){
      if(this.User.user_type===5){
          return this.$store.state.CardDataList
        }
      else{
        return this.CardTypeDataList
      }
    }
  },
  components: {
    JsonExcel
  },
  created () {
    this.clientWidth= document.documentElement.clientWidth
    this.User = util.JesToken()
    this.getParkDataList()
  },
  methods: {
    getParkDataList(){
        if(this.User.user_type===5){
            this.GetDataList()
            if(this.$store.state.ParkDataList.length===0){
              this.$store.dispatch('getParkDataList')
            }
            if(this.$store.state.CardDataList.length===0){
              this.$store.dispatch('getCardDataList')
            }
        }
        else{
          util.Get('parkmanage/getparkdatalist').then(res => {
            if (res.rpStatus === 10000) {
               this.ParkDataList=res.list
               if(res.list.length>0){
                 this.searchForm.pk_id=res.list[0].pk_id
                 this.getCardDataList()
                 this.GetDataList()
               }
            }
          })
        }
    },
    getCardDataList(){
      util.Get('card/getcarddatalist?id='+this.searchForm.pk_id).then(res => {
          if (res.rpStatus === 10000) {
            this.CardTypeDataList = res.list
          }
      })
    },
    parkChanged(value){
      this.searchForm.pk_id=value
      this.getCardDataList()
      this.searchForm.card_id=0
      this.GetDataList()
    },

    GetDataList () {
      if (this.searchForm.intime === null || this.searchForm.intime.length === 0) {
        this.searchForm.begintime = ''
        this.searchForm.endtime = ''
      } else {
        this.searchForm.begintime = this.searchForm.intime[0]
        this.searchForm.endtime = this.searchForm.intime[1]
      }
      util.Get('enterlog/getEnterLogDataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(element => {
              element.picdata = []
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search () {
      if(util.CheckUserAuth('4-1-1')){
        this.GetDataList()
      }
    },
    handleSizeChange(pageSize){
      this.searchForm.pageSize=pageSize
      this.GetDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    expandChanged (row, expandedRows) {
      var arr = expandedRows.filter(a => a.log_id === row.log_id)
      if (arr.length > 0) {
        // 展开行
        if (row.picdata.length === 0 && row.log_in_picfullname !== null && row.log_in_picfullname !== undefined && row.log_in_picfullname !== '') {
            util.Get('picture/getparkpictrue?parkId='+row.park_id+'&logId='+row.log_id+'&picInName=' + row.log_in_picfullname).then(res=>{
                if(res.rpStatus===10000){
                    var data=JSON.parse(res.list[0])
                    var log=row
                    if(row.park_id!==data.parkId||row.log_id!==data.logId){
                      var arr=this.DataList.filter(a=>a.park_id===data.parkId&&a.log_id==data.logId)
                      if(arr.length>0)
                        log=arr[0]
                    }
                    this.$set(log, 'picdata', [data.in_pic,data.out_pic])
                }
                else{
                  this.$message.error(res.rpMsg)
                }
              })
        }
      }
    },
    ImgClick () {
      this.imgDialogVisible = true
    },

    refreshUpload(){
      util.Send('refresh_upload_inpark',this.searchForm.pk_id)
    },

    //一位多车
    find(){
      util.Get('enterlog/getcarcodestatus', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(element => {
              element.picdata = []
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },

    clearCommand(cmd){
      if(cmd==="a"){
        var rows=this.$refs.enterTable.selection
        if(rows.length===0){
          this.$message.error('请勾选强制出场的车辆')
          return
        }
        var nos=''
        var arr=[]
        rows.forEach(a=>{
          nos+= a.log_plateno+'，'
          arr.push(a.log_plateno)
        })
        
        if(util.CheckUserAuth('4-1-3')){
          this.$confirm('确定强制车辆“'+nos+'”？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            util.Post('enterlog/ForceOut',{nos:arr,state:0,pk_id:this.searchForm.pk_id}).then(res => {
              if (res.rpStatus === 10000) {
                 this.GetDataList() 
                 this.$message.success('强制车辆出场成功')
                } else { this.$message.error(res.rpMsg) }
            })
          }).catch(e => e)
        }
      }
      if(cmd==="b"){
        if(util.CheckUserAuth('4-1-3')){
            this.$confirm('确定清理所有在场车辆？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              util.Post('enterlog/ForceOut',{state:1,pk_id:this.searchForm.pk_id}).then(res => {
                if (res.rpStatus === 10000) {
                  this.GetDataList() 
                  this.$message.success('清理所有在场车辆成功')
                  } else { this.$message.error(res.rpMsg) }
              })
            }).catch(e => e)
        }
      }
      if(cmd==="c"){
        this.dialogVisible=true
        this.title='按条件清理'
        this.clearForm.plateno=''
        this.clearForm.card_id=0
        this.clearForm.time=[]
      }
      if(cmd==="d"){
        this.BLdialogVisible=true
        this.title='补录入场'
        this.blform.plateno=''
        this.blform.time=''
      }
    },
    submit(){
      this.clearForm.pk_id=this.searchForm.pk_id
      this.clearForm.begintime=''
      this.clearForm.endtime=''
      if(this.clearForm.time&&this.clearForm.time.length>0){
        this.clearForm.begintime=this.clearForm.time[0]
        this.clearForm.endtime=this.clearForm.time[1]
      }
      this.clearForm.state=2
      util.Post('enterlog/ForceOut',this.clearForm).then(res=>{
        if(res.rpStatus===10000){
          this.dialogVisible=false
          this.$message.success('清理成功')
          this.GetDataList()
        }
        else{
          this.$message.error(res.rpMsg)
        }
      })
    },
    BL(){
      this.$refs.blform.validate((valid) => {
        if (valid) {
          util.Post('enterlog/insertplateno', this.blform).then(res => {
            if (res.rpStatus === 10000) {
              this.GetDataList()
              this.BLdialogVisible = false
              this.$message.success('补录入场成功')
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },

    // 导出
    async createExportData () {
      if(util.CheckUserAuth('4-1-1')){
        this.searchForm.pageNo = 0
        var form = JSON.parse(JSON.stringify(this.searchForm))
        form.pageNo = 0

        var result = await util.Get('enterlog/getEnterLogDataList', form)
        if (result.rpStatus === 10000) {
          this.exportData = result.list
          return this.exportData
        }
      }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
.a{
  font-size: 13px;
  margin-bottom: 5px;
  font-family:sans-serif
}
</style>
